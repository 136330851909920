import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Progress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import CssBaseline from '@material-ui/core/CssBaseline';
import AutoChangeTheme from '../components/AutoThemeChange'

import { initApp } from '../redux/app'

import TrackingRoute from '../components/tracking/route'
import Helmet from 'react-helmet'


import { I18n } from '@lingui/react'
import { t } from '@lingui/macro'
import { getSitename } from '../redux/intl';

const SignLazy = React.lazy(() => import('./sign'))
const Sign = props => (
    <React.Suspense fallback={null}>
        <SignLazy {...props}/>
    </React.Suspense>
)

const AuthRoutesLazy = React.lazy(() => import('./auth-routes'))
const AuthRoutes = props => (
    <React.Suspense fallback={null}>
        <AuthRoutesLazy {...props}/>
    </React.Suspense>
)

class Routes extends React.PureComponent {

    static displayName = 'Routes'
    
    componentWillMount() {
        this.props.initApp();
    }

    render() {
        const { init, logged, classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <I18n>{this._renderHelmet}</I18n>
                {!init ? (
                    <div className={classes.loader}>
                        <Progress/>
                    </div>
                ) : logged ? (
                    <React.Fragment>
                        <AutoChangeTheme />
                        <Router>
                            <React.Fragment>
                                <Route component={TrackingRoute}/>
                                <Route component={AuthRoutes}/>
                            </React.Fragment>
                        </Router>
                    </React.Fragment>
                ) : (
                    <Router>
                        <React.Fragment>
                            <Route component={TrackingRoute}/>
                            <Route component={Sign} />
                        </React.Fragment>
                    </Router>
                )}
            </React.Fragment>
        )
    }

    _renderHelmet = ({ i18n }) => {
        const sitename = getSitename()
        return (
            <Helmet 
            titleTemplate={`%s - ${sitename}`}
            defaultTitle={i18n._(t`Запознанства за разкрепостени`)}/>
        )
    }
}

export default withStyles(theme=>({
    loader: {
        position:'absolute',
        top:0,
        left:0,
        right:0,
        bottom:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}))(connect(({ app }) => ({
    init: app.init,
    logged: app.user ? true : false
}), dispatch => ({
    initApp: () => dispatch(initApp())
}))(Routes));