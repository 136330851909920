import * as request from '../api/search'

const ACTION_LOADING = 'AF/LOADING',
    ACTION_LOAD = 'AF/LOAD',
    ACTION_DELETE = 'AF/DELETE',
    ACTION_DIALOG = 'AF/DIALOG';

const initState = {
    loading: true,
    list: [],
    openDialog: false
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTION_LOAD:
            return {
                ...state,
                loading: false,
                list: payload
            }
        case ACTION_DELETE:
            return {
                ...state,
                list: state.list.filter(item=>item.id !== payload)
            }
        case ACTION_DIALOG:
            return {
                ...state,
                openDialog: payload
            }
        default:
            return state;
    }
}

export function load() {
    return async dispatch => {
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        try {
            const { filters } = await request.autoFiltersLoad();
            dispatch({
                type: ACTION_LOAD,
                payload: filters
            })
        } catch(e) {
            dispatch({
                type: ACTION_LOADING,
                payload: false
            })
        }
    }
}

export function save() {
    return async dispatch => {
        try {
            await request.autoFitlersSave();
            dispatch(load())
        } catch(e) {}
    }
}

export function deleteFilter(id) {
    return async dispatch => {
        dispatch({
            type: ACTION_DELETE,
            payload: id
        })
        try {
            await request.autoFiltersDelete(id)
        } catch(e) {}
    }
}

export function deleteAll() {
    return async dispatch => {
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        try {
            await request.autoFiltersDeleteAll();
            dispatch({
                type: ACTION_LOAD,
                payload: []
            })
        } catch(e) {
            dispatch({
                type: ACTION_LOADING,
                payload: false
            })
        }
    }
}

export function openDialog(open) {
    return {
        type: ACTION_DIALOG,
        payload: (new Date().getTime())
    }
}