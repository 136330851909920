import { get, post } from './_core'

export function channels({ sex, excludeChannels, excludeSex, global, offset, limit }){
    return get('/channels/load', {
        sex,
        exclude_channels: excludeChannels,
        exclude_sex: excludeSex,
        offset,
        global,
        limit
    }) 
}

export function getChannel(){
    return get('/channels/get_channel')
}

export function create(data){
    return post('/channels/create', {data});
}

export function join(channel, code, pin){
    return post('/channels/join', {
        channel,
        code,
        pin
    })
}

export function stop(channel){
    return post('/channels/stop', {
        channel: channel
    })
}

export function message(data){
    return post('/channels/message', data)
}

export function streamWith(channelId, userId){
    return post('/channels/stream_with', {
        channel: channelId,
        user_id: userId
    })
}

export function reward(){
    return post('/channels/reward')
}

export function banChannel(channelId){
    return post('/channels/ban_channel', {
        channel: channelId
    })
}

export function deleteMessage(channelId, messageId){
    return post('/channels/delete_message', {
        channel: channelId,
        message: messageId
    })
}

export function getBanned(channel, offset, limit){
    return get('/channels/get_banned', {
        channel,
        offset,
        limit
    })
}

export function removeBan(channel, userId){
    return post('/channels/remove_ban', {
        channel,
        user_id: userId
    })
}

export function promote(channel){
    return post('/channels/promote', {
        channel
    })
}