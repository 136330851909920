import { getSiteName } from "../api/util"

const ACTION_CHAGNE_LANG = 'INTL/CHANGE_LANGUAGE'
let SITENAME = null;

const initState = {
    language: 'bg'
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_CHAGNE_LANG:
            return { 
                ...state, 
                language: payload.language
            }
        default:
            return state;
    }
}

export function changeLanguage(language) {
    return async dispatch=>{
        dispatch({
            type: ACTION_CHAGNE_LANG,
            payload: {
                language
            }
        })
    }
}

export async function initSitename(){
    try {
        if(!SITENAME){
            const result = await getSiteName();
            if(result.sitename){
                SITENAME = result.sitename
            }
        }
        return SITENAME
    } catch {}
}

export function setToSessionStorage(key, data){
    window[key] = data
}

export function getFromSessionStorage(key){
    return window[key]
}

export function deleteFromSessionStorage(key){
    delete window[key]
}

export function getSitename() {
    return SITENAME
}