import { initUser } from '../api/user'
import merge from 'deepmerge'
import { load as loadAutoFilters } from './autofilters'

export const ACTION_INIT = 'APP/INIT'
const ACTION_SET_USER = 'APP/SET_USER'
const ACTION_APP_HANDLER = 'APP/APP_HANDLER'
const ACTION_THEME = 'APP/CHANGE_THEME'

const THEME_STORAGE_KEY = 'theme'
const THEME_AUTO_CHANGE_STORAGE_KEY = 'theme_auto_change'


const initState = {
    init: false,
    user: null,
    handler: null,
    theme: null
}

export default function(state = initState, { type, payload }) {
    switch(type) {
        case ACTION_INIT:
            return { 
                ...state, 
                init: true, 
                user: payload.user,
                theme: payload.theme
            }
        case ACTION_SET_USER:
            return { 
                ...state, 
                user: payload.user,
                theme: 'theme' in payload ? payload.theme : state.theme
            }
        case ACTION_APP_HANDLER:
            return {
                ...state,
                handler: payload
            }
        case ACTION_THEME:
            return {
                ...state,
                theme: payload
            }
        default:
            return state;
    }
}

export function initApp() {
    return async dispatch => {
        let user = null;
        try {
            user = (await initUser()).user;
        } catch (e) { }

        let theme = window.localStorage.getItem(THEME_STORAGE_KEY) || null;
        if(!user) {
            theme = null;
        }

        dispatch({
            type: ACTION_INIT,
            payload: {
                user,
                theme
            }
        })
        
        if(user) {
            dispatch(loadAutoFilters())
        }
    }
}

export function setUser(user) {
    return dispatch => {
        let theme = window.localStorage.getItem(THEME_STORAGE_KEY) || null;
        dispatch({
            type: ACTION_SET_USER,
            payload: {
                user,
                theme
            }
        })
    }
}

export function updateUser(data) {
    return (dispatch, getState)=>{
        let user = getState().app.user;
        dispatch({
            type: ACTION_SET_USER,
            payload: {
                user: merge(user, data, {
                    customMerge: key => {
                        if(key === 'search' || key === 'expect'){
                            return (a, b) => {
                                Object.keys(b).forEach(k => {
                                    if(a[k]){
                                        a[k] = b[k]
                                    }
                                })
                                return a
                            }
                        } 
                    }
                })
            }
        })
    }
}

export function appHandler(type, data) {
    return {
        type: ACTION_APP_HANDLER,
        payload: {
            type, 
            data
        }
    }
}

export function changeTheme(theme, autoChange) {
    return dispatch => {
        window.localStorage.setItem(THEME_STORAGE_KEY, theme);
        if(!autoChange){
            window.localStorage.setItem(THEME_AUTO_CHANGE_STORAGE_KEY, false);
        }
        dispatch({
            type: ACTION_THEME,
            payload: theme
        })
    }
}