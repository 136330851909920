
import { getList, markAsSeen } from '../api/stories'

const limit = 20

const initState = {
    loading: false,
    list: [],
    filter: 'all',
    offset: 0,
    hasMore: true,
    update: 0
}

const ACTION_LOADING = 'STORIES/LOADING',
    ACTION_LOAD = 'STORIES/LOAD',
    ACTION_UPDATE = 'STORIES/VIEW',
    ACTION_DELETE = 'STORIES/DELETE',
    ACTION_FILTER = 'STORIES/FILTER'

function getAllSeen(stories) {
    let allSeen = true
    for (const story of stories) {
        if (!story.isArchive && !story.seen) {
            allSeen = false
        }
    }
    return allSeen
}

export default function (state=initState, { type, payload }) {
    switch(type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: true,
                list: payload.loadMore ? state.list : [],
                filter: payload.filter
            }
        case ACTION_LOAD:
            return {
                ...state,
                loading: false,
                list: ((payload.loadMore ? state.list : []).concat(payload.list)).map((row, key) => {
                    row.story_index = key
                    return row
                }),
                offset: payload.offset,
                hasMore: payload.offset + limit < payload.total,
                filter: payload.filter
            }
        case ACTION_UPDATE:
            return {
                ...state,
                list: state.list.map(row=>{
                    if(row.user.id === payload.userId) {
                        const stories = row.stories.map(story=>{
                            if(!payload.storyId || (payload.storyId && story.story_id === payload.storyId)) {
                                return {
                                    ...story,
                                    ...payload.update
                                }
                            }
                            return story
                        })
                        return {
                            ...row,
                            seen: getAllSeen(stories),
                            stories
                        }
                    }
                    return row
                })
            }
        case ACTION_DELETE: 
            // const list = state.list;
            // list.forEach((row, key) => {
            //     if(row.user.id === payload.userId) {
            //         const stories = row.stories.filter(story => story.story_id !== payload.storyId)
            //         if(row.stories.length !== stories.length){
            //             if(!stories.length){
            //                 list.splice(key, 1);
            //             } else if(row.total && row.total > 0){
            //                 row.total--;
            //                 row.stories = stories;
            //             }
            //         }  
            //     }
            // })

            // return {
            //     ...state,
            //     list: list
            // }

            return {
                ...state,
                list: state.list.map((row) => {
                    if (row.user.id === payload.userId) {
                        const stories = row.stories.filter(story => story.story_id !== payload.storyId);
                        if (row.stories.length !== stories.length) {
                            if (!stories.length) {
                                return null;
                            } else if (row.total && row.total > 0) {
                                return {
                                    ...row,
                                    total: row.total - 1,
                                    stories: stories
                                };
                            }
                        }
                    }
                    return row;
                })
                .filter(row => row !== null)
            }
        case ACTION_FILTER:
            return {
                ...state,
                filter: payload.filter
            }
        default:
            return state
    }
}

export function load({
    loadMore,
    filter
}) {
    return async (dispatch, getState) => {
        const stories = getState().stories
        if(stories.loading || (loadMore && !stories.hasMore)) {
            return;
        }
        if(!filter) {
            filter = stories.filter
        }
        let offset = stories.offset
        let excludeIds = []
        if(loadMore) {
            offset += limit
            excludeIds = stories.list.map(row=>row.user.id).join(',')
        }
        dispatch({
            type: ACTION_LOADING,
            payload: {
                loadMore,
                filter
            }
        })
        try {
            let match
            let filters = { 
                excludeIds,
                offset, 
                limit
            }
            if(filter === 'favorites') {
                filters.favorites = 1
            } else if(filter === 'popular') {
                filters.sort = 'popular'
            } else if((match = filter.match(/sex([0-9]+)/))) {
                filters.sex = match[1]
            } else if(filter === 'all'){
                const user = getState().app.user
                filters.sex2 = user.search.id
            }
            const { list, total } = await getList(filters)
            dispatch({
                type: ACTION_LOAD,
                payload: {
                    loadMore,
                    offset,
                    total,
                    list,
                    filter
                }
            })
        } catch(e) {
            console.log(e)
        }
    }
}

export function view(userId, storyId) {
    return (dispatch) => {
        markAsSeen(userId, storyId).catch(err=>null)
        dispatch({
            type: ACTION_UPDATE,
            payload: {
                userId,
                storyId,
                update: {
                    seen: true
                }
            }
        })
    }
}

export function pay(userId, storyId) {
    return (dispatch) => (
        dispatch({
            type: ACTION_UPDATE,
            payload: {
                userId,
                update: {
                    paid: false
                }
            }
        })
    )
}

export function setReaction(userId, storyId, reaction){
    return (dispatch) => (
        dispatch({
            type: ACTION_UPDATE,
            payload: {
                userId,
                storyId,
                update: {
                    reaction: reaction
                }
            }
        })
    )
}

export function removeStoryReaction(userId, storyId){
    return (dispatch) => (
        dispatch({
            type: ACTION_UPDATE,
            payload: {
                userId,
                storyId,
                update: {
                    reaction: null
                }
            }
        })
    )
}

export function deleteStory(userId, storyId){
    return (dispatch) => (
        dispatch({
            type: ACTION_DELETE,
            payload: {
                userId,
                storyId
            }
        })
    )
}