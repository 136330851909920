import { friends, favourites } from '../api/list'

const initState = {
    loading: false,
    lastUpdateAt: null,
    users: []
}

const ACTION_LOADING = 'ONLINE/LOADING',
    ACTION_LOAD = 'ONLINE/LOAD'

export default function(state=initState, { type, payload }) {
    switch(type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case ACTION_LOAD:
            return {
                ...state,
                users: payload,
                loading: false,
                lastUpdateAt: new Date().getTime()
            }
        default:
            return state;
    }
}

export function load() {
    return async (dispatch, getState) => {
        if(getState().online.lastUpdateAt+(60*1000) > new Date().getTime()) {
            return;
        }
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        let friendsList = [], 
            favsList = [];

        try {
            const { list } = await friends({
                online: 1
            }, {
                offset:0,
                limit: 500
            })
            friendsList = list.map(row=>({
                ...row,
                type: 'friends'
            }))
        } catch(e) {}

        try {
            const { list } = await favourites({
                online: 1
            }, {
                offset:0,
                limit: 500
            })
            favsList = list.map(row=>({
                ...row,
                type: 'favorites'
            }))
        } catch(e) {}

        dispatch({
            type: ACTION_LOAD,
            payload: friendsList.concat(favsList)
        })
    } 
}