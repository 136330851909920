import React from 'react';

import 'typeface-roboto';

import { Provider } from 'react-redux'
import store from './redux'

import IntlProvider from './components/intl'
import Routes from './routes'
import ThemeProvider from './components/ThemeProvider'

import moment from 'moment'
import 'moment/locale/bg'

import { SnackbarProvider } from 'notistack'

import ErrorHandler from './components/error'

moment.locale('bg')

class App extends React.Component {
  
  static displayName = 'App'
  
  render() {
    return (
        <Provider store={store}>
          <ErrorHandler>
            <ThemeProvider>
              <IntlProvider>
                <SnackbarProvider maxSnack={3} autoHideDuration={1600}>
                  <Routes />
                </SnackbarProvider>
              </IntlProvider>
            </ThemeProvider>
          </ErrorHandler>
        </Provider>
    );
  }
}

export default App;