import React from 'react'
import ga from './ga'
import { connect } from 'react-redux'
import { setGaClientId } from '../../redux/updates'

export default connect(state=>({
    userId: state.app.user ? Number(state.app.user.id) : null
}))(class TrackingRoute extends React.PureComponent {

    static displayName = 'TrackingRoute'
    
    componentDidMount() {
        ga.set({
            'dimension1': 'react'
        })
        this._setUser(this.props);
        this._trackPage(this.props.location.pathname + this.props.location.search)
        ga.ga(tracker=>{
            this.props.dispatch(setGaClientId(tracker.get('clientId')))
        })
    }

    componentDidUpdate(nextProps) {
        if(nextProps.userId !== this.props.userId) {
            this._setUser(nextProps);
        }
        const currentPage = nextProps.location.pathname + nextProps.location.search;
        const nextPage = this.props.location.pathname + this.props.location.search;
        if (currentPage !== nextPage) {
            this._trackPage(nextPage)
        }
    }

    _setUser = ({ userId }) => {
        ga.set({
            userId: userId
        })
        if(window.gtag) {
            window.gtag('config', process.env.REACT_APP_GOOGLE_TAG_ID, {
                userId
            })
        }
    }

    _trackPage = page => {
        window.scrollTo(0, 0);
        ga.pageview(page)
    }

    render() {
        return null;
    }
})