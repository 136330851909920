import * as chatApi from '../api/chat'
import { users } from '../api/util'
import moment from 'moment'
import { HandleAppError } from '../redux'


const STORAGE_ACTIVE_CHATS = 'chats'

const ACTION_LOADED = 'CHATS/LOADED',
    ACTION_REFRESH = 'CHATS/REFRESH',
    ACTION_CREATE = 'CHATS/CREATE',
    ACTION_REGISTER = 'CHATS/REGISTER',
    ACTION_APPEND = 'CHATS/APPEND',
    ACTION_READ = 'CHATS/READ',
    ACTION_ARCHIVE = 'CHATS/ARCHIVE',
    ACTION_UNARCHIVE = 'CHATS/UNARCHIVE',
    ACTION_DELETE = 'CHATS/DELETE',
    ACTION_PRIORITY = 'CHATS/PRIORITY',

    ACTION_ADD_ACTIVE = 'CHATS/ADD_ACTIVE',
    ACTION_REMOVE_ACTIVE = 'CHATS/REMOVE_ACTIVE',
    ACTION_CHANGE_ACTIVE = 'CHATS/CHANGE_ACTIVE_STATE',

    ACTION_OTHER_READ = 'CHATS/OTHER_READ',
    ACTION_OTHER_ARCHIVE = 'CHATS/OTHER_ARCHIVE',
    ACTION_OTHER_UNARCHIVE = 'CHATS/OTHER_UNARCHIVE',
    ACTION_OTHER_DELETE = 'CHATS/OTHER_UNRACHIVE',
    ACTION_OTHER_OPEN = 'CHATS/OTHER_OPEN',
    ACTION_OTHER_CLOSE = 'CHATS/OTHER_CLOSE',
    ACTION_OTHER_TYPING = 'CHATS/OTHER_TYPING',
    ACTION_OTHER_IMAGE_PREVIEW = 'CHATS/OTHER_IMAGE_PREVIEW',
    ACTION_OTHER_REMOVE_MESSAGE = 'CHATS/OTHER_REMOVE_MESSAGE',
    ACTION_OTHER_EDIT_MESSAGE = 'CHATS/OTHER_EDIT_MESSAGE',
    ACTION_CHAT_SOUND = 'CHATS/SOUND',
    ACTION_CHAT_MESSAGE_DELETE = 'CHATS/MESSAGE_DELETE',
    ACTION_CHAT_MESSAGE_EDIT = 'CHATS/MESSAGE_EDIT'

const StorageChatSound = 'chat-sound'
const chatSound = window.localStorage.getItem(StorageChatSound)

const initState = {
    loading: true,
    refreshing: false,
    list: [],
    active: [],
    sound: chatSound === null ? true : (chatSound === 'true')
}

export function SessionModifier(row, saved) {
    const ts = moment().unix();
    const session = {
        loading: null,
        config: null,
        user: row.user,
        total: row.total || 0,
        unread: row.unread || 0,
        created: row.updated || ts,
        updated: row.updated || 0,
        messages: [],
        other_read: row.other_read || false,
        my_last_read: row.my_last_read || false,
        other_last_read: row.other_last_read | false,
        other_archive: row.other_archive || false,
        me_archive: row.me_archive || false,
        active: false,
        favourite: row.favourite || false,
        other_favourite: row.other_favourite || false,
        priority: row.priority || false,
        high_priority: row.high_priority || 0,
        me_priority: row.me_priority || false,
        other_priority: row.other_priority || false,
        chat_archive: row.chat_archive || false,
        other_write: row.other_write || false,
        author: row.author,
        attachs_meta: row.attachs_meta || {},
        messages_info: {
            received: 0,
            sended: 0,
            total: 0
        },
        session_images: {
            files: [],
            meta: {},
            total: 0,
            from_me: 0
        },
        session_videos: {
            messages: [],
            videos: [],
            total: 0,
            from_me: 0
        },
        typing: false,
        saved
    };

    if(row.messages) {
        row.messages.forEach(message => {
            MessageModifier(session, message);
        })
    }
    
    return session;
}

function MessageModifier(session, message, immutable=false) {
    let oldMessage;

    if (immutable && message.id) {
        session.messages = session.messages.map(row=>{
            if(String(row.id) === String(message.id)) {
                oldMessage = {
                    ...row,
                    content: row.content + "\n" + message.content,
                    files: row.files.concat(message.files),
                    videos: row.videos.concat(message.videos)
                };
                return oldMessage;
            }
            return row;
        })
    }

    if (!oldMessage) {
        if(immutable) {
            session.messages = session.messages.concat();
        }
        session.messages.push({
            id: message.id,
            me: message.me,
            time: message.time,
            content: message.content,
            files: message.files || [],
            videos: message.videos || [],
            location: message.location || null,
            location_address: message.location_address,
            is_deleted: message.is_deleted ? true : false
        });

        session.last_message_info = session.messages[session.messages.length-1];

        session.messages_info.total += 1;

        if (message.me) {
            session.messages_info.sended += 1;
        } else {
            session.messages_info.received += 1;
        }

        if (message.me) {
            session.me_write = true;
            session.me_last_message_info = message;
        }
    }

    if (!('can_edit' in message) || !('can_delete' in message)) {
        if (message.me && !message.is_deleted) {
            message.can_edit = true;
            message.can_delete = true;
        } else {
            message.can_edit = false;
            message.can_delete = false;
        }
    }

    if (message.files.length > 0) {
        session.session_images.total += message.files.length;
        message.files.forEach(function(file) {
            session.session_images.files.push(file);
            session.session_images.meta[file] = {
                me: message.me
            }
            if (message.me) {
                session.session_images.from_me++;
            }
        });
    }

    if (message.videos.length > 0) {
        message.videos.forEach(function (video) {
            session.session_videos.videos.push(video);
            if (message.me) {
                session.session_videos.from_me++;
            }
        });
        session.session_videos.messages.push(message);
        session.session_videos.total += message.videos.length;
    }
}

function MessageAppend(session, authorId, message, User) {
    const ts = moment().unix()

    session.other_read = false;
    session.me_archive = false;
    session.other_archive = false;
    session.chat_archive = false;
    session.chat_deleted = false;

    const isLoggedUser = Number(authorId) === Number(User.id);

    if (!isLoggedUser) {
        session.unread++;
    } else {
        session.unread = 0;
    }

    if (message.priority !== undefined && message.priority) {
        session.priority = true;
        delete message.priority;
    }

    session.total++;
    session.updated = ts;

    MessageModifier(session, {
        time: moment().unix(),
        ...message,
        me: isLoggedUser
    }, true);

    if (session.author === undefined) {
        session.author = session.messages[0].me ? User.id : message.user_id;
    }

    return session;
}

function FindUpdateSession(list, user, update) {
    let updated;
    let newList = list.map(session => {
        if (Number(session.user.id) === Number(user.id)) {
            updated = true;
            return {
                ...session,
                ...update
            }
        }
        return session;
    })
    if (updated) {
        return newList;
    }
}

function saveActive(active) {
    window.localStorage.setItem(STORAGE_ACTIVE_CHATS, JSON.stringify(active))
}

function loadActive() {
    let active = [];
    try {
        active = JSON.parse(window.localStorage.getItem(STORAGE_ACTIVE_CHATS))
        if(!Array.isArray(active)) {
            active = [];
        }
    } catch(e) {}
    return active;
}

function changeActiveItem(active, userId, state) {
    if('open/minimized' === state) {
        let found = active.filter(row=>Number(row.userId) === Number(userId))[0];
        if(found) {
            return active;
        } else {
            state = 'minimized';
        }
    }

    
    if('delete' === state) {
        active = active.filter(row=>Number(row.userId) !== Number(userId));
    } else {
        let found;
        let index
        active = active.map((row, key)=>{
            if(Number(row.userId) === Number(userId)) {
                found = true;
                return {
                    ...row,
                    state
                }
            } 
            /**
             * Minimize all other chats
             */
            if(state === 'open') {
                index = key
                return {
                    ...row,
                    state: 'minimized'
                }
            }
            return row;
        });

        if(!found) {
            active = active.concat();
            active.unshift({
                userId: Number(userId),
                state
            })
            if(active.length > 5) {
                if(index >= 4){
                    let last = active[4];
                    active[4] = active[index+1]
                    active[index+1] = last;
                }
                active = active.slice(0, 5);
            }
        }
    }

    saveActive(active)

    return active;
}

const ACTION_CALLBACKS = {
    [ACTION_LOADED]: (state, payload) => {
        return {
            ...state,
            loading: false,
            refreshing: false,
            list: payload.map(item => SessionModifier(item, true)),
            active: loadActive().map(row=>({
                ...row,
                state: 'minimized' // Always minimized popup!
            }))
        }
    },
    [ACTION_REFRESH]: (state) => {
        return {
            ...state,
            refreshing: true
        }
    },
    [ACTION_CREATE]: (state, { user, openState }) => {
        let session = state.list.filter(session => Number(session.user.id) === Number(user.id))[0]
        let list;
        if (!session) {
            session = SessionModifier({
                user,
                messages: []
            }, false);
            list = state.list.concat();
            list.unshift(session);
            state = {
                ...state,
                list
            }
        }
        return {
            ...state,
            active: changeActiveItem(state.active, user.id, openState)
        }
    },
    'VIDEO_CHAT/START': (state, { user, invite }) => {
        if(!invite) {
            return ACTION_CALLBACKS[ACTION_CREATE](state, {
                user,
                openState: 'open/minimized'
            })
        }
        return state;
    },
    [ACTION_REGISTER]: (state, { user, loading, config, error }) => {
        var list = FindUpdateSession(state.list, user, {
            loading,
            config,
            error
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_APPEND]: (state, payload) => {
        var session
        var list = state.list.map(sess=>{
                if(Number(sess.user.id) === Number(payload.other.id)) {
                    session = {
                        ...sess,
                        saved: true
                    }
                    return session;
                }
                return sess;
            })

        if (!session) {
            session = SessionModifier({
                user: payload.other,
                messages: []
            }, true);
            list = list.concat();
            list.push(session);
        }

        MessageAppend(session, payload.authorId, payload.message, payload.User);

        return {
            ...state,
            list,
            active: changeActiveItem(state.active, payload.other.id, 'open/minimized')
        }
    },
    [ACTION_READ]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            unread: 0
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_DELETE]: (state,payload) => {
        var list = FindUpdateSession(state.list, payload, {
            is_deleted: true
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_ARCHIVE]: (state,payload) => {
        var list = FindUpdateSession(state.list, payload, {
            me_archive: true,
            chat_archive: moment().unix() + 1209600
        })
        return {
            ...state,
            list,
            active: changeActiveItem(state.active, payload.id, 'delete') 
        }
    },
    [ACTION_UNARCHIVE]: (state,payload) => {
        var list = FindUpdateSession(state.list, payload, {
            me_archive: false,
            chat_archive: null
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_PRIORITY]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload.session.user, {
            priority: moment().add(31, 'days').format('x'),
            priority_user: payload.user.id
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_ADD_ACTIVE]: (state, payload) => {
        return {
            ...state,
            active: changeActiveItem(state.active, payload.user.id, 'open')
        };
    },
    [ACTION_REMOVE_ACTIVE]: (state, { session, removeFromList }) => {
        const active = state.active.filter(({ userId })=>Number(userId) !== Number(session.user.id));
        saveActive(active)
        let list = state.list
        if(removeFromList) {
            list = state.list.filter(row=>Number(row.user.id) !== Number(session.user.id));
        }
        return {
            ...state,
            active,
            list
        }
    },
    [ACTION_CHANGE_ACTIVE]: (state, { user, newState }) => {
        return {
            ...state,
            active: changeActiveItem(state.active, user.id, newState)
        }
    },
    [ACTION_OTHER_OPEN]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            chat_open: true,
            other_last_read: moment().unix()
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_CLOSE]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            chat_open: false
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_DELETE]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            chat_deleted: false
        })
        if (list) {
            var session = list.filter(session => Number(session.user.id) === Number(payload.id))[0];
            session.messages = session.messages.map(msg => {
                return {
                    ...msg,
                    is_deleted: true
                }
            })
        }
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_ARCHIVE]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            other_archive: true,
            priority: false,
            me_priority: false,
            other_priority: false
        })
        if(list) {
            var session = list.filter(session => Number(session.user.id) === Number(payload.id))[0];
            if(session && session.me_archive) {
                list = list.map(sess=>{
                    if(session === sess) {
                        return {
                            ...sess,
                            chat_archive: moment().add('14', 'days').format('x')
                        }
                    }
                    return sess;
                })
            }
        }
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_UNARCHIVE]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            other_archive: false,
            chat_archive: false
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_READ]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            other_read: true
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_TYPING]: (state, payload) => {
        var list = FindUpdateSession(state.list, payload, {
            typing: moment().add(5, 'seconds')
        })
        return list ? { ...state, list } : state;
    },
    [ACTION_OTHER_IMAGE_PREVIEW]: (state, { user, file: { filename, attachs_meta } }) => {
        return {
            ...state,
            list: state.list.map(session=>{
                if(Number(session.user.id) === Number(user.id)) {
                    return {
                        ...session,
                        attachs_meta: {
                            ...session.attachs_meta,
                            [filename]: attachs_meta
                        }
                    }
                }
                return session;
            })
        }
    },
    [ACTION_OTHER_REMOVE_MESSAGE]: (state, { user, message }) => {
        return {
            ...state,
            list: state.list.map(session=>{
                if(Number(session.user.id) === Number(user.id)) {
                    const newSession = {
                        ...session,
                        messages: session.messages.map(msg=>{
                            if(String(msg.id) === String(message.id)) {
                                return {
                                    ...msg,
                                    is_deleted: true
                                }
                            }
                            return msg;
                        })
                    }

                    return newSession;
                }
                return session;
            })
        }
    }, 
    [ACTION_OTHER_EDIT_MESSAGE]: (state, { user, message}) => {
        return {
            ...state,
            list: state.list.map(session=>{
                if(Number(session.user.id) === Number(user.id)) {
                    return {
                        ...session,
                        messages: session.messages.map(msg=>{
                            if(String(msg.id) === String(message.id)) {
                                return {
                                    ...msg,
                                    ...message
                                }
                            }
                            return msg;
                        })
                    }
                }
                return session;
            })
        }
    },
    [ACTION_CHAT_SOUND]: (state, enabled) => {
        const sound = Boolean(enabled)
        window.localStorage.setItem(StorageChatSound, sound)
        return {
            ...state,
            sound
        }
    },
    [ACTION_CHAT_MESSAGE_DELETE]: (state, {userId, messageId}) => {
        var session
        var list = state.list.map(sess => {
            if(Number(sess.user.id) === Number(userId)) {
                session = {
                    ...sess,
                    messages: sess.messages.map(msg => {
                        if(msg.id === messageId){
                            return {
                                ...msg,
                                is_deleted: true
                            }
                        }
                        return msg
                    })
                }
                return session;
            }
            return sess;
        })
        return {
            ...state,
            list
        }
    },
    [ACTION_CHAT_MESSAGE_EDIT]: (state, {userId, messageId, content}) => {
        var session
        var list = state.list.map(sess => {
            if(Number(sess.user.id) === Number(userId)) {
                session = {
                    ...sess,
                    messages: sess.messages.map(msg => {
                        if(msg.id === messageId){
                            return {
                                ...msg,
                                content: content
                            }
                        }
                        return msg
                    })
                }
                return session;
            }
            return sess;
        })
        return {
            ...state,
            list
        }
    }
}

export default function (state = initState, { type, payload }) {
    if(ACTION_CALLBACKS[type]) {
        return ACTION_CALLBACKS[type](state, payload)
    }
    return state;
}

export function loadChats(refresh) {
    return async dispatch => {
        if (refresh) {
            dispatch({
                type: ACTION_REFRESH
            })
        }
        try {
            const result = await chatApi.chats()
            dispatch({
                type: ACTION_LOADED,
                payload: result
            })
        } catch(e) {}
    }
}

export function startSession(other, openState='open') {
    return async dispatch => {
        dispatch({
            type: ACTION_CREATE,
            payload: {
                user: other,
                openState
            }
        })
    }
}

export function registerSession(user) {
    return async dispatch => {
        dispatch({
            type: ACTION_REGISTER,
            payload: {
                user,
                loading: true
            }
        })
        try {
            const config = await chatApi.register(user.id);
            dispatch({
                type: ACTION_REGISTER,
                payload: {
                    user,
                    loading: false,
                    config
                }
            })
        } catch(error) {
            dispatch({
                type: ACTION_REGISTER,
                payload: {
                    user,
                    loading: false,
                    error
                }
            })
        }
    }
}

export function appendMessage(authorId, otherId, message) {
    return async (dispatch, getState) => {
        const { app: { user }, chat } = getState();

        const session = chat.list.filter(row=>Number(row.user.id) === Number(otherId))[0];
        let other;

        if(session) {
            other = session.user;
        } else {
            try {
                const result = await users([otherId])
                other = result[0]
            } catch(e) {
                // Reject!
                return;
            }
        }

        dispatch({
            type: ACTION_APPEND,
            payload: {
                authorId,
                message,
                other,
                User: user
            }
        })

    }
}

function flagSession(action, postAction, session) {
    return async dispatch => {
        if(session.messages.length > 0) {
            await chatApi.flagSession(postAction, session.user.id)
        }
        dispatch({
            type: action,
            payload: session.user
        })
    }
}

export function readChat(session) {
    if(session.unread > 0) {
        return flagSession(ACTION_READ, 'read', session)
    }
    return () => null
}

export function archiveChat(session) {
    return dispatch => {
        dispatch(flagSession(ACTION_ARCHIVE, 'archive', session))
    }
}

export function unarchiveChat(session) {
    return dispatch => {
        dispatch(flagSession(ACTION_UNARCHIVE, 'unarchive', session))
    }
}

export function deleteChat(session) {
    return {
        type: ACTION_DELETE,
        payload: session
    }
}

export function addActiveChat(session) {
    return {
        type: ACTION_ADD_ACTIVE,
        payload: session
    }
}

export function removeActiveChat(session, removeFromList) {
    return async dispatch => {
        dispatch({
            type: ACTION_REMOVE_ACTIVE,
            payload: {
                session,
                removeFromList
            }
        })
    }
}

export function changeActiveChat(session, state) {
    return dispatch=>{
        if(state === 'minimized' && session.config) {
            chatApi.close(session.user.id).then(()=>null).catch(()=>null)
        }
        dispatch({
            type: ACTION_CHANGE_ACTIVE,
            payload: {
                user: session.user,
                newState: state
            }
        });
    }
}

export function onOtherOpen(user) {
    return {
        type: ACTION_OTHER_OPEN,
        payload: user
    }
}

export function onOtherClose(user) {
    return {
        type: ACTION_OTHER_CLOSE,
        payload: user
    }
}

export function onOtherDelete(user) {
    return {
        type: ACTION_OTHER_DELETE,
        payload: user
    }
}

export function onOtherArchive(user) {
    return {
        type: ACTION_OTHER_ARCHIVE,
        payload: user
    }
}

export function onOtherUnarchive(user) {
    return {
        type: ACTION_OTHER_UNARCHIVE,
        payload: user
    }
}

export function onOtherRead(user) {
    return {
        type: ACTION_OTHER_READ,
        payload: user
    }
}

export function onOtherTyping(user) {
    return {
        type: ACTION_OTHER_TYPING,
        payload: user
    }
}

export function onOtherImagePreview(user, file) {
    return {
        type: ACTION_OTHER_IMAGE_PREVIEW,
        payload: {
            user,
            file
        }
    }
}

export function onOtherRemoveMessage(user, message) {
    return {
        type: ACTION_OTHER_REMOVE_MESSAGE,
        payload: {
            user,
            message
        }
    }
}

export function onOtherEditMessage(user, message) {
    return {
        type: ACTION_OTHER_EDIT_MESSAGE,
        payload: {
            user,
            message
        }
    }
}

export function makeSessionPriority(session) {
    return (dispatch, getState) => {
        dispatch({
            type: ACTION_PRIORITY,
            payload: {
                session,
                user: getState().app.user
            }
        })
    }
}

export function turnSound(enabled) {
    return {
        type: ACTION_CHAT_SOUND,
        payload: enabled
    }
}

export function deleteMessage(userId, messageId) {
    return async dispatch => {
        try {
            const result = await chatApi.deleteMessage(userId, {message: {id: messageId}})
            if(!result.status){
                return
            }
            dispatch({
                type: ACTION_CHAT_MESSAGE_DELETE,
                payload: {
                    userId,
                    messageId
                }
            })
        } catch(e) {
            HandleAppError(e)
        }
    }
}

export function editMessage(userId, messageId, content){
    return async dispatch => {
        try {
            const result = await chatApi.editMessage(userId, {message: {id: messageId, content: content}})
            if(!result.status){
                return
            }
            dispatch({
                type: ACTION_CHAT_MESSAGE_EDIT,
                payload: {
                    userId,
                    messageId,
                    content
                }
            })
        } catch(e) {
            HandleAppError(e)
        }
    }
}