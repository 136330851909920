import React from 'react'

import { connect } from 'react-redux'
import { getTheme } from '../selector/theme'

import { MuiThemeProvider } from '@material-ui/core/styles'

const Cmp = React.memo(({ theme, children })=>(
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
))
Cmp.displayName = 'ThemeProvider'

export default connect(state=>({
    theme: getTheme(state)
}))(Cmp)