import { users } from '../api/util';
import { createVideoChannelSubscription } from '../components/updates/real-time';
import SimplePeer from 'simple-peer'
import { videoRequest } from '../api/chat';
import { HandleAppError } from '.';

const ACTION_START = 'VIDEO_CHAT/START',
    ACTION_STOP = 'VIDEO_CHAT/STOP',
    ACTION_ACCEPT = 'VIDEO_CHAT/ACCEPT';

export const STATUS = {
    PENDING: 'pending',
    ACCEPT: 'accept'
}

const initState = {
    sessions: []
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_START:
            return {
                ...state,
                sessions: state.sessions.filter(session=>{
                    if(Number(session.user.id) === Number(payload.user.id)) {
                        session.subscription.unsubscribe();
                        session.subscription.removeAllListeners()
                        return false;
                    }
                    return true;
                }).concat([{
                    user: payload.user,
                    subscription: payload.subscription,
                    status: payload.status,
                    invite: payload.invite,
                    isAudio: payload.isAudio
                }])
            }
        case ACTION_STOP:
            return {
                ...state,
                sessions: state.sessions.filter(({ user })=>Number(user.id) !== Number(payload.userId))
            }
        case ACTION_ACCEPT:
            return {
                ...state,
                sessions: state.sessions.map(session=>{
                    if(Number(session.user.id) === Number(payload.userId)) {
                        return {
                            ...session,
                            status: STATUS.ACCEPT
                        }
                    } else if(session.status === STATUS.ACCEPT) {
                        return {
                            ...session,
                            status: STATUS.PENDING
                        }
                    }
                    return session;
                })
            }
        case 'CHATS/ARCHIVE':
        case 'CHATS/DELETE':
        case 'CHATS/REMOVE_ACTIVE':
            return {
                ...state,
                sessions: state.sessions.filter(session=>{
                    if(Number(session.user.id) !== Number(payload.id)) {
                        return true;
                    }
                    session.subscription.unsubscribe();
                    session.subscription.removeAllListeners()
                    return false;
                })
            }
        default:
            return state
    }
}

export function startVideoChat(userId, subscription, status=STATUS.PENDING, invite=false, isAudio = false) {
    return async dispatch => {
        try {
            if(invite){
                await videoRequest(userId, {is_audio: isAudio, check: true})
            }
            const result = await users([userId])
            if(result[0] && Number(result[0].id) === Number(userId)) {
                dispatch({
                    type: ACTION_START,
                    payload: {
                        user: result[0],
                        subscription,
                        status,
                        invite,
                        isAudio
                    }
                })
            }
        } catch(e) {
            dispatch(stopVideoChat(userId))
            HandleAppError(e, () => {
                dispatch(startVideoChat(userId, subscription, status, invite, isAudio))
            });
        }
    }
}

export function stopVideoChat(userId) {
    return (dispatch, getState)=>{
        const session = getState().videoChat.sessions.filter(item=>Number(item.user.id) === Number(userId))[0];
        if(session) {
            session.subscription.unsubscribe();
            session.subscription.removeAllListeners()
            dispatch({
                type: ACTION_STOP,
                payload: {
                    userId
                }
            })
        }
    }
}

export function onNewInvite(userId, isAudio = false) {
    return (dispatch, getState)=>{

        const subscription = createVideoChannelSubscription(getState().app.user.id, userId);

        const cleanup = () => {
            dispatch(stopVideoChat(userId))
        };

        subscription.on('subscribe', function(){
            subscription.publish({
                type: 'status',
                available: true,
                supported: SimplePeer.WEBRTC_SUPPORT
            }).then(function(){
                if(SimplePeer.WEBRTC_SUPPORT) {
                    subscription.on('leave', cleanup);
                    dispatch(startVideoChat(userId, subscription, STATUS.PENDING, false, isAudio));
                } else {
                    cleanup();
                }
            }).catch(cleanup);
        });
    }
}

export function acceptInvite(userId) {
    return {
        type: ACTION_ACCEPT,
        payload: {
            userId
        }
    }
}