import React from 'react'
import { connect } from 'react-redux'
import Centrifuge from 'centrifuge'
import { Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { API_URL } from '../../api/_core'

const Subjects = {};

var _client;

function createSubject({ userId, url, token, channel }) {
    if(!Subjects[channel]) {
        Subjects[channel] = new Subject();

        if(!_client) {
            _client = new Centrifuge(url, {
                subscribeEndpoint: `${API_URL}/realtime-channel`
            });
            _client.setToken(token)
            _client.on('disconnect', ()=>{
                console.log('disconnected!')
            })
            _client.connect()
        }

        _client.subscribe(`${channel}#${userId}`, context=>{
            Subjects[channel].next({
                channel: channel,
                context
            });
        })
    }
    return Subjects[channel].pipe(
        filter(({ channel: broadcast }) => channel === broadcast),
        map(({ context }) => context)
    );
}

class RealTime extends React.PureComponent {

    _subscription;

    componentWillMount() {
        this._subscription = createSubject(this.props).subscribe(this.props.onUpdate)
    }

    componentWillUnmount() {
        if(this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    render() {
        return null
    }
}

export default connect(({ app: { user }})=>({
    userId: user.id,
    url: user.realtime.url, 
    token: user.realtime.token
}))(RealTime)

export function disconnect() {
    try {
        _client.disconnect()
    } catch(e) {
        console.log(e.message);
    }
}

export function createVideoChannelSubscription(userId, otherId) {
    const channel = `$videochat:${Math.min(userId, otherId)}-${Math.max(userId, otherId)}`;
    return _client.subscribe(channel)
}

export function createLiveChannelSubscription(url, token, channelId){
    const centofuge = new Centrifuge(url, {
        subscribeEndpoint: `${API_URL}/channels/realtime`
    })
    centofuge.setToken(token)
    centofuge.connect()
    return centofuge.subscribe(`$videochat:${channelId}`)
}