import { getMoods } from "../api/settings";

const ACTION_LOADING = 'MOODS/LOADING'
const ACTION_LOADED = 'MOODS/LOADED'

const initState = {
    loading: true,
    list: []
}

export default function(state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTION_LOADED:
            return {
                ...state,
                loading: false,
                list: payload
            }
        default:
            return state;
    }
}

export function loadMoods() {
    return async dispatch => {
        try {
            dispatch({
                type: ACTION_LOADING,
                payload: true
            })
            const list = await getMoods()
            dispatch({
                type: ACTION_LOADED,
                payload: list
            })
        } catch (e) { }
    }
}