import { get, post } from './_core'

export function filters(filters) {
    return get('/list_filters', filters, true).then(data=>{
        const map = {};
        data.forEach(item=>{
            switch(item.type) {
                case 'range':
                    item.selected = {
                        from: item.values.from[item.selected.from] ? item.values.from[item.selected.from].id : null,
                        to: item.values.to[item.selected.to] ? item.values.to[item.selected.to].id : null
                    }
                    break;
                default:
                    item.selected = item.values[item.selected]
                    break;
            }
            map[item.name] = item;
        })
        return map;
    })
}

export function friends(filters, { offset, limit }) {
    return get('/friends', {
        ...filters,
        offset, 
        limit
    })
}

export function favourites(filters, { offset, limit }) {
    return get('/favourites', {
        ...filters,
        offset, 
        limit
    })
}

export function blockIn(filters, { offset, limit }) {
    return get('/block/in', {
        ...filters,
        offset, 
        limit
    })
}

export function blockOut(filters, { offset, limit }) {
    return get('/block/out', {
        ...filters,
        offset, 
        limit
    })
}

export function fans(filters, { offset, limit }) {
    return get('/fans', {
        ...filters,
        offset, 
        limit
    })
}

export function likes(filters, { offset, limit }) {
    return get('/likes', {
        ...filters,
        offset, 
        limit
    })
}

export function visits(filters, { offset, limit }) {
    return get('/visits', {
        ...filters,
        offset, 
        limit
    })
}

export function visited(filters, { offset, limit }) {
    return get('/visited', {
        ...filters,
        offset, 
        limit
    })
}

export function friendsInvitations(filters, { offset, limit }) {
    return get('/friends/invitations', {
        ...filters,
        offset, 
        limit
    })
}

export function friendsInvitationsAll(filters, {offset, limit}) {
    return get('/friends/invitations/all', {
        ...filters,
        offset, 
        limit
    })
}

export function toppics({ cat, sex, city }, { offset, limit }) {
    return get('/toppics', {
        cat,
        sex,
        city,
        offset,
        limit
    })
}

export function visitsBlocked({ offset, limit }) {
    return get('/visit_blocked', {
        offset,
        limit
    })
}

export function clearVisitBlocked() {
    return post('/visit_blocked/clear')
}

export function clearVisits() {
    return post('/visits/clear')
}

export function clearVisited() {
    return post('/visited/clear')
}

export function getHronology(type, {offset, limit}) {
    return get(`/hronology/${type}`, {
        offset,
        limit
    })
}