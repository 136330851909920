import React from 'react'

import { Container, Box, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro'
import StackTrace from 'stacktrace-js'

const EnableErrors = process.env.NODE_ENV === 'production';

class ErrorHandler extends React.PureComponent {

    static displayName = 'ErrorHandler'
    
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    async componentDidCatch(error, errorInfo) {
        if(EnableErrors && !error.message.match('Loading chunk')) {
            let stack = [];
            try {
                stack = await StackTrace.fromError(error);
            } catch(e) {}
            this._report(error, stack, errorInfo);
        }
    }

    _report = (error, stack, errorInfo) => {
        fetch(`${process.env.REACT_APP_API_URL}/track/js-error`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                error: {
                    message: error.message,
                    userId: this.props.userId,
                    location: window.location.href,
                    stack: stack.filter(item=>item.fileName.substr(0,15) !== '../node_modules'),
                    componentStack: errorInfo.componentStack
                }
            })
        }).then(()=>null).catch(()=>null)
    }

    static getDerivedStateFromError() {
        return {
            error: EnableErrors
        }
    }

    render() {
        if (this.state.error) {
            return (
                <Container maxWidth="sm">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <video src="http://img.gepime.com/error-robot.mp4" autoPlay loop muted playsInline/>
                        <Typography variant="h6" gutterBottom align="center">
                            <Trans>Хм...нещо лошо се случи.</Trans>
                        </Typography>
                        <Typography variant="body2" gutterBottom align="center">
                            <Trans>Извиняваме се за недоразомението и ще се постараем в най-кратък срок да оправи нещата!</Trans>
                        </Typography>
                        <Box mt={2}>
                            <Button variant="contained" color="secondary" component='a' href="/">
                                <Trans>Опитайте отново</Trans>
                            </Button>
                        </Box>
                    </Box>
                </Container>
            );
        }
        return this.props.children;
    }
}

export default connect(state=>({
    userId: state.app.user ? state.app.user.id : ''
}))(ErrorHandler)