import React, { useEffect } from 'react'
import SunCalc from 'suncalc'
import { changeTheme } from '../redux/app'

import { connect } from 'react-redux'
import {currentIpLocation} from '../api/util'

const Cmp = React.memo(({ changeTheme })=>{
        useEffect(() => {
            try {
                const autoChangeActive = !window.localStorage.getItem('theme_auto_change');
                if(autoChangeActive !== false){
                    currentIpLocation().then((data) => {  
                        const sunTimes = SunCalc.getTimes(new Date(), parseFloat(data.lat), parseFloat(data.lng));
                        const sunrise = Math.round(sunTimes.sunrise.getTime() / 1000)
                        const sunset = Math.round(sunTimes.sunset.getTime() / 1000)
                        const currentTime = Math.round(new Date().getTime() / 1000)
                        const theme = window.localStorage.getItem('theme') || null
                        if(theme === 'dark' && sunrise <= currentTime && currentTime < sunset){
                            changeTheme('default', true)
                        } else if(theme !== 'dark' && sunset <= currentTime){
                            changeTheme('dark', true)
                        }
                    })
                }
            } catch(e){}
        })
    return null
})

Cmp.displayName = 'AutoChangeTheme'

export default connect(null,
    dispatch => ({
        changeTheme: (theme, autoChange) => dispatch(changeTheme(theme, autoChange))
}))(Cmp)