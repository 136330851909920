import { updates } from '../api/util'
import { ApiError } from '../api/_core';
import merge from 'deepmerge'
import moment from 'moment'

const ACTION_LOADING = 'UPDATES/LOADING',
    ACTION_DATA = 'UPDATES/DATA',
    ACTION_GA_CLIENT = 'UPDATES/GA_CLIENT_ID',
    ACTION_SET_UPDATE = 'APP/SET_UPDATE';

const ASK_TIME = 3600 * 6;

const initState = {
    init: false,
    loading: false,
    data: {},
    gaClientId: null,
    reload: false,
    lastUpdate: moment().unix()
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTION_DATA:
            return {
                ...state,
                init: true,
                loading: false,
                data: payload,
                reload: moment().unix() - state.lastUpdate > ASK_TIME
            }
        case ACTION_GA_CLIENT:
            return {
                ...state,
                gaClientId: payload
            }
        case ACTION_SET_UPDATE:
            return {
                ...state,
                lastUpdate: moment().unix()
            }
        default:
            return state;
    }
}

export function update(data=null) {
    return async (dispatch, getState) => {
        if(data) {
            dispatch({
                type: ACTION_DATA,
                payload: merge(getState().updates.data, data)
            })
            return;
        }
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        try {
            const result = await updates({
                gaClientId: getState().updates.gaClientId
            })
            dispatch({
                type: ACTION_DATA,
                payload: result
            })
        } catch(e) {
            if(e instanceof ApiError && e.data && (e.data.status === 'NOT_LOGGED' || e.data.status === 'LOCK')) {
                window.location = `/logout`
            }
        }
    }
}

export function setGaClientId(clientId) {
    return {
        type: ACTION_GA_CLIENT,
        payload: clientId
    }
}

export function setUpdate() {
    return dispatch => {
        dispatch({
            type: ACTION_SET_UPDATE,
        })
    }
}