import { get, post } from './_core'

function modifyFilters(filters) {
    const map = {};
    filters.forEach(item=>{
        switch(item.type) {
            case 'range':
            case 'range_select':
                item.selected = {
                    from: item.values.from[item.selected.from] ? item.values.from[item.selected.from].id : null,
                    to: item.values.to[item.selected.to] ? item.values.to[item.selected.to].id : null
                }
                break;
            case 'check':
            case 'input':
                break;
            default:
                item.selected = item.values[item.selected]
                break;
        }
        map[item.name] = item;
    })
    return map;
}

export function search(filters, { hot_limit, offset, limit }) {
    return get('/search/', {
        ...filters,
        hot_limit,
        offset, limit
    }, true).then(result=>{
        if(result.filters && !result.modifiedFilters) {
            result.modifiedFilters = true
            result.filters = modifyFilters(result.filters);
        }
        return result;
    })
}

export function autoFiltersLoad() {
    return get('/search/autofilters/load')
}

export function autoFitlersSave() {
    return post('/search/autofilters/save')
}

export function autoFiltersDelete(id) {
    return post('/search/autofilters/delete', {
        id
    })
}

export function autoFiltersDeleteAll() {
    return post('/search/autofilters/delete/all')
}

export function searchFilters(queryParams) {
    return get('/search/filters', queryParams).then(result=>modifyFilters(result))
}

export function mediaFeed(filters, type, offset, limit){
    return get('/search/media_feed', {
        ...filters,
        type,
        offset,
        limit
    })
}