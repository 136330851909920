import { get, post } from './_core'

export function getPremiumPlusUsers(){
    return get('/get_premium_plus_onlines')
}

export function load(){
    return get('/settings/premium_plus') 
}

export function saveSettings(data){
    return post('/settings/premium_plus/settings', data)
}
export function createAnnonce(form){
    return post('/settings/premium_plus/annonce', form)
}

export function premiumClubUsers(filters, offset, limit){
    return get('/premium_club/users', {
        offset,
        limit,
        ...filters
    }) 
}

export function premiumClubSave(data){
    return post('/premium_club/save', data);
}

export function premiumClubInfo(){
    return get('/premium_club/info');
}

export function setAnnonceView(userId){
    return post(`/settings/set_annonce_view/${userId}`)
}