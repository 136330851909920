import { getPremiumPlusUsers } from "../api/premium"
import { getUsersShort, getUsers } from '../api/user'

const ACTION_LOADING = 'PREMIUM_PLUS_USERS/LOADING'
const ACTION_LOADED = 'PREMIUM_PLUS_USERS/LOADED'
const ACTION_PREMIUM_PLUS_ONLINE_LOADING = 'PREMIUM_PLUS_ONLINE/LOADING'
const ACTION_PREMIUM_PLUS_ONLINE_LOADED = 'PREMIUM_PLUS_ONLINE/LOADED'
const ACTION_PREMIUM_PLUS_ONLINE_REMOVE = 'PREMIUM_PLUS_ONLINE/REMOVE'
const ACTION_PREMIUM_PLUS_ANNONCE_ADD = 'PREMIUM_PLUS_ANNONCE/ADD'
const ACTION_PREMIUM_PLUS_ANNONCE_REMOVE = 'PREMIUM_PLUS_ANNONCE/REMOVE'

const initState = {
    loading: true,
    list: [],
    uLoading: true,
    users: [],
    annonces: []
}

export default function(state = initState, { type, payload }) {
    let index
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTION_LOADED:
            return {
                ...state,
                loading: false,
                list: payload
            }
        case ACTION_PREMIUM_PLUS_ONLINE_LOADING:
            return {
                ...state,
                uLoading: payload
            }
        case ACTION_PREMIUM_PLUS_ONLINE_LOADED:
            return {
                ...state,
                uLoading: false,
                users: state.users.concat(payload)
            }
        case ACTION_PREMIUM_PLUS_ONLINE_REMOVE:
            const users = state.users.concat([])
            index = users.indexOf(payload)
            users.splice(index, 1)
            return {
                ...state,
                uLoading: false,
                users: users
            }
        case ACTION_PREMIUM_PLUS_ANNONCE_ADD:
            return {
                ...state,
                annonces: state.annonces.concat(payload)
            }
        case ACTION_PREMIUM_PLUS_ANNONCE_REMOVE:
            const annonces = state.annonces.concat([])
            index = null
            annonces.forEach((row, k) => {
                if(row.key === payload){
                    index = k
                }
            })
            if(index !== null){
                annonces.splice(index, 1)
            }
            return {
                ...state,
                annonces: annonces
            }  
        default:
            return state;
    }
}

export function loadPremiumPlusUsers() {
    return async dispatch => {
        try {
            dispatch({
                type: ACTION_LOADING,
                payload: true
            })
            const list = await getPremiumPlusUsers()
            dispatch({
                type: ACTION_LOADED,
                payload: list
            })
        } catch (e) { }
    }
}

export function onPremiumPlusOnline(userId) {
    return async dispatch => {
        try {
            dispatch({
                type: ACTION_PREMIUM_PLUS_ONLINE_LOADING,
                payload: true
            })
            const result = await getUsersShort([userId])
            dispatch({
                type: ACTION_PREMIUM_PLUS_ONLINE_LOADED,
                payload: result
            })
           
        } catch (e) { }
    }
}

export function removePremiumPlusAnimated(userId){
    return  dispatch => {
        dispatch({
            type: ACTION_PREMIUM_PLUS_ONLINE_REMOVE,
            payload: userId
        })
    }
}

export function onAnnonceMessage(userId, message){
    return async dispatch => {
        try {
            const user = await getUsers([userId])
            dispatch({
                type: ACTION_PREMIUM_PLUS_ANNONCE_ADD,
                payload: {
                    user: user[0],
                    message,
                    key: user[0].id
                }
            })
           
        } catch (e) { }
    }
}

export function removeAnnonce(userId){
    return  dispatch => {
        dispatch({
            type: ACTION_PREMIUM_PLUS_ANNONCE_REMOVE,
            payload: userId
        })
    }
}