import { get, post } from './_core'

export function chats() {
    return get('/chat/')
}

export function flagSession(action, userId) {
    return post(`/chat/${action}/${userId}`);
}

export function send({ userId, message, files, videos, location }) {
    return post(`/chat/send/${userId}`, {
        message,
        files,
        videos,
        latitude: location ? location.lat : null,
        longitude: location ? location.lng : null,
        formatted_address: location ? location.formatted_address : null
    })
}

export function register(userId) {
    return post(`/chat/register/${userId}`)
}

export function close(userId) {
    return post(`/chat/close/${userId}`)
}

export function priority(userId) {
    return post(`/chat/priority_chat/${userId}`)
}

export function forceVisibility(userId) {
    return post(`/chat/force/${userId}`)
}

export function videoRequest(userId, data) {
    return post(`/chat/video-request/${userId}`, data)
}

export function editMessage(userId, message){
    return post(`/chat/edit/${userId}`, message)
}

export function deleteMessage(userId, message){
    return post(`/chat/delete/${userId}`, message)
}

export function setVideoChatStatus(data){
    return post(`/chat/set_video_chat_status`, data)
}