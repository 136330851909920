import { post, get } from './_core'

function convertEditFields(fields) {
    const map = {};
    fields.forEach(row=>{
        map[row.field] = row;
        if(row.options) {
            switch(row.options.type) {
                case 'select_range':
                case 'input':
                case 'avatar':
                case 'email':
                case 'video':
                    break;
                default:
                    const multiple = Array.isArray(row.options.selected);
                    if(multiple) {
                        row.options.selected = row.options.selected.map(val=>String(val));
                    } else {
                        row.options.selected = String(row.options.selected)
                    }
                    row.options.selected = row.options.values.filter(val=>{
                        if(multiple) {
                            return row.options.selected.indexOf(String(val.id)) > -1;
                        }
                        return row.options.selected === String(val.id);
                    })
                    if(!multiple) {
                        row.options.selected = row.options.selected[0]
                    }
                    break;
            }
        }
    })
    return map;
}

export function getPrices(gift, device, reason, target) {
    return post('/settings/prices', {
        gift: gift,
        device: device,
        reason: reason,
        target: target
    })
}

export function creditsToVip(type, period, gift) {
    return post('/settings/vip_credits', {
        type: type,
        period: period,
        gift: gift
    })
}

export function activateService(code, userId) {
    return post(`/settings/activate/${code}`, {
        user_id: userId
    })
}

export function getPremiumPaymentMethods(type, reason) {
    return get(`/settings/premium_methods`, {
        type: type,
        reason: reason
    })
}

export function getField(field) {
    return get(`/settings/${field}`).then(result=>convertEditFields(result))
}

export function saveFields(fields) {
    return post(`/settings/save`, {
        fields
    })
}

export function saveGender(gender) {
    return post(`/settings/save_gender`, {
        gender
    })
}

export function getBirthday() {
    return get('/settings/birthday')
}

export function saveBirthday({ year, month, day}) {
    return post('/settings/birthday', {
        year, month, day
    })
}

export function changeUsername(username) {
    return post('/settings/username', {
        username,
        change: true
    })
}

export function changeEmail(email) {
    return post('/settings/email', {
        email
    })
}

export function changePassword({old, password, password2 }) {
    return post('/settings/password', {
        old, password, password2
    })
}

export function getGsms(status, limit) {
    return get(`/settings/gsms/${status}`, {
        ...limit
    })
}

export function gsmVisibility(id, visibility) {
    return post('/settings/gsms/visibility', {
        id,
        visibility
    })
}

export function gsmSend(number) {
    return post('/settings/gsms/send', {
        number
    })
}

export function gsmConfirm({ id, code, visible }) {
    return post('/settings/gsms/confirm', {
        id,
        code,
        visible
    })
}

export function gsmDelete(id) {
    return post('/settings/gsms/delete', {
        id
    })
}

export function deleteProfile(data) {
    if(data) {
        const { reason, text, emailUnlock } = data;
        return post('/settings/delete', {
            reason,
            text,
            email_unlock: emailUnlock
        })
    }
    return get('/settings/delete')
}

export function getAnonymity() {
    return get('/settings/anonymity').then(result=>{
        const map = {};
        result.forEach(item=>{
            map[item.name] = item;
            item.values = item.values.map(val=>({
                id: String(val.id),
                label: val.value,
                vip: val.vip,
                premium: val.premium
            }))
            item.selected = item.values.filter(val=>val.id === String(item.selected))[0];
        })
        return map;
    })
}

export function saveAnonymity(settings) {
    return post('/settings/anonymity', {
        settings
    })
}

export function getPrivacyFilters(filter, { ageText }) {
    return get(`/settings/filters/${filter}`).then(result=>{
        const fields = {};

        fields.edit = result.edit;
        fields.choice = result.choice;
        
        fields.haspic = result.haspic ? true : false;

        fields.sex = result.sex.map(option=>{
            const selected = [];
            const values = option.options.map(item=>{
                item.id = String(item.id)
                const row = {
                    id: item.id,
                    label: item.value,
                    disabled: item.disabled ? true : false
                };
                if(item.checked) {
                    selected.push(row)
                }
                return row;
            })
            return {
                title: option.title,
                selected,
                values
            }
        })

        fields.age = {
            field: 'age',
            text: ageText,
            options: {
                type: 'select_range',
                values: {
                    from: [],
                    to: []
                },
                selected: {
                    from: null,
                    to: null
                }
            }
        }

        const keys = ['from', 'to']
        keys.forEach(key=>{
            result.age[key].forEach(val=>{
                const row = {
                    id: Number(val.id),
                    label: val.value
                }
                if(val.checked) {
                    fields.age.options.selected[key] = row.id;
                }
                fields.age.options.values[key].push(row)
            })
        })
        
        return fields;
    })
}

export function savePrivacyFilters(filter, filters) {
    return post(`/settings/filters/${filter}`, {
        filters
    })
}

export function getLoginHistory({ offset, limit }) {
    return get('/settings/loginhistory', { offset, limit })
}

export function getPushNotifications() {
    return get('/settings/notifications/push')
}

export function setPushNotifications(settings) {
    return post('/settings/notifications/push', {
        settings
    })
}

export function getMoods() {
    return get('/settings/moods')
}

export function setMood(value) {
    return post(`/settings/mood/${value}`)
}

export function sendEmailValidation() {
    return post(`/settings/email/validation`)
}

export function checkSmsCode(code, type) {
    return get(`/settings/checkcode`, {
        code,
        type
    })
}

export function getEditWizzard() {
    return get('/settings/profile/wizard').then(result=>({
        fields: convertEditFields(result.edit),
        total: result.total,
        success: result.success
    }))
}

export function creditsHistory({ offset, limit }) {
    return get('/settings/creditshistory', {
        offset,
        limit
    })
}

export function getSavedCards({ limit, offset }) {
    return get('/settings/saved_cards', {
        limit,
        offset
    })
}

export function deleteSavedCard(cardId) {
    return post('/settings/delete_card', {
        card_id: cardId
    })
}

export function getRecurringOptions() {
    return get('/settings/recurring/options')
}

export function cancelSubscription(subscription) {
    return post('/settings/recurring/cancel', {
        subscription
    })
}

export function getPlans() {
    return get('/settings/plans')
}

export function getSponsorshipOptions() {
    return get('/settings/get_sponsorship_options')
}

export function saveSponsorshipOptions(options) {
    return post('/settings/save_sponsorship_options', {
        ...options
    })
}

export function checkPromoCode(code, type) {
    return get(`/settings/check_promo_code`, {
        code,
        type
    })
}

export function changeLanguage(lang) {
    return post('/change_language', {
        lang
    })
}