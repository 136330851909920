import { get, post } from './_core'

export function markAsSeen(userId, storyId) {
    return post(`/stories/${userId}/seen_story`, {
        story_id: storyId
    })
}

export function getStoryPrice(userId, storyId, pay) {
    return get(`/stories/${userId}/get_story_price?story_id=${storyId}&pay=${pay}`)
}

export function payForStory(userId, storyId) {
    return post(`/stories/${userId}/pay_for_story`, {
        story_id: storyId
    })
}

export function payPackage(userId, storyId){
    return post(`/stories/${userId}/pay_package`, {
        story_id: storyId
    })
}

export function getList({ sex, favorites, sort, sex2, excludeIds, offset, limit }) {
    return post(`/stories/list`, {
        sex,
        sex2,
        favorites,
        sort,
        exclude_ids: excludeIds,
        offset,
        limit
    }).then(result=>result.stories)
}

export function getProfileStories(userId, offset, limit, type, skip) {
    return get(`/stories/profile`, {
        user_id: userId,
        offset,
        limit,
        type,
        skip
    })
}
export function getFavoriteStories(userId, offset, limit) {
    return get(`/stories/favorites`, {
        user_id: userId,
        offset,
        limit
    })
}
export function getReactedStories(userId, offset, limit) {
    return get(`/stories/reacted_stories`, {
        user_id: userId,
        offset,
        limit
    })
}

export function deleteStory(userId, storyId){
    return post(`/stories/delete_story`, {
        story_id: storyId,
        user_id: userId
    })
}

export function removeReaction(userId, storyId){
    return post(`/stories/${userId}/remove_reaction`, {
        story_id: storyId
    })
}
