import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

import intl from './intl'
import app, { appHandler } from './app'
import geolocation from './geolocation'
import updates from './updates'
import chat from './chat'
import moods from './moods'
import autofilters from './autofilters'
import online from './online'
import notifications from './notifications'
import profileWizzard from './profile-wizzard'
import videoChat from './video-chat'
import stories from './stories'
import premium from './premium'
import liveChannels from './live-channels'

const reducers = combineReducers({
    intl,
    app,
    geolocation,
    updates,
    chat,
    moods,
    autofilters,
    online,
    notifications,
    profileWizzard,
    videoChat,
    stories,
    premium,
    liveChannels
});

const store = createStore(reducers, applyMiddleware(thunk))

export default store;

export function HandleAppDialog(dialog, data) {
    store.dispatch(appHandler('dialog', {
        dialog,
        data
    }))
}

export function HandleAppError(error, resolver) {
    error.resolver = resolver;
    store.dispatch(appHandler('error', error))
}