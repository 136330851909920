import moment from 'moment';
import { getEditWizzard } from '../api/settings'

const ACTION_LOAD = 'PROFILE/WIZZARD/LOAD',
    ACTION_LOADING = 'PROFILE/WIZZARD/LOADING',
    ACTION_UPDATE = 'PROFILE/WIZZARD/UPDATE',
    ACTION_HIDE = 'PROFILE/WIZZARD/HIDE';

const STORAGE_KEY_HIDDEN = 'profile-wizzard';

const initState = {
    loaded: false,
    loading: false,
    total: 0,
    success: 0,
    fields: null,
    selected: null,
    hidden: false
}

function pickSelected(fields) {
    for(let name in fields) {
        const field = fields[name];
        if(!field.options.selected || (field.options.selected && Array.isArray(field.options.selected) && field.options.selected.length === 0)) {
            return {
                [name]: field
            }
        }
    }
    return null;
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loaded: true,
                loading: payload
            }
        case ACTION_LOAD:
            const selected = pickSelected(payload.fields)
            if(selected && selected.email){
                payload.hidden = false
            }
            return {
                ...state,
                loading: false,
                ...payload,
                selected: selected,
                hidden: payload.hidden
            }
        case ACTION_UPDATE:
            var fields = {
                ...state.fields,
                ...payload
            }
            return {
                ...state,
                fields,
                selected: pickSelected(fields),
                success: state.success + 1
            }
        case ACTION_HIDE:
            return {
                ...state,
                hidden: true
            }
        default:
            return state;
    }
}

export function loadWizzard(refresh=false) {
    return async (dispatch, getState) => {
        if(!refresh && getState().profileWizzard.loaded) {
            return;
        }
        
        const localStorageHiddenExpire = Number(window.localStorage.getItem(STORAGE_KEY_HIDDEN))
        const hidden = localStorageHiddenExpire && (localStorageHiddenExpire + (86400 * 7))  > moment().unix() ? true : false

        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        try {
            const { fields, total, success } = await getEditWizzard()
            dispatch({
                type: ACTION_LOAD,
                payload: {
                    fields,
                    total,
                    success,
                    hidden
                }
            })
        } catch (e) {
            dispatch({
                type: ACTION_LOADING,
                payload: false
            })
        }
    }
}

export function onFieldsUpdate(fields) {
    return {
        type: ACTION_UPDATE,
        payload: fields
    }
}

export function hide() {
    window.localStorage.setItem(STORAGE_KEY_HIDDEN, moment().unix());
    return {
        type: ACTION_HIDE,
        payload: true
    }
}