import { channels, create, join, getChannel, streamWith } from "../api/live-channels"
import { createLiveChannelSubscription } from '../components/updates/real-time'
import { HandleAppError } from './'
import GATracker from '../components/tracking/ga'

const ACTION_LOADING = 'LIVE_CHANNELS/LOADING'
const ACTION_LOADED = 'LIVE_CHANNELS/LOADED'
const ACTION_GET_USER_CHANNEL = 'LIVE_CHANNELS/GET_USER_CHANNEL'
const ACTION_CREATE_CHANNEL = 'LIVE_CHANNELS/CREATE_CHANNEL'
const ACTION_JOIN_CHANNEL = 'LIVE_CHANNELS/JOIN_CHANNEL'
const ACTION_LEAVE_CHANNEL = 'LIVE_CHANNELS/LEAVE_CHANNEL'
const ACTION_UPDATE_CHANNEL = 'LIVE_CHANNELS/UPDATE_CHANNEL'
const ACTION_STREAM_WITH_CHANNEL = 'LIVE_CHANNELS/STREAM_WITH'
const ACTION_LOAD_ERROR_CHANNEL = 'LIVE_CHANNELS/LOAD_ERROR'
const ACTION_GLOBAL_LOADING = 'LIVE_CHANNELS/GLOBAL_LOADING'
const ACTION_GLOBAL_LOADED = 'LIVE_CHANNELS/GLOBAL_LOADED'

let limit = 24

const initState = {
    loading: false,
    list: [],
    offset: 0,
    hasMore: true,
    joinedList: [],
    userChannel: null,
    globalLoading: false,
    globalChannels: []
}

export default function(state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: true,
                list: payload.loadMore ? state.list : [],
            }
        case ACTION_LOADED:
            return {
                ...state,
                loading: false,
                list: ((payload.loadMore ? state.list : []).concat(payload.list)),
                hasMore: payload.offset + limit < payload.total
            }
        case ACTION_GET_USER_CHANNEL:
            return {
                ...state,
                userChannel: payload
            }
        case ACTION_JOIN_CHANNEL:
            if(state.joinedList.length > 2){
                return state
            }
            const found = state.joinedList.filter(row => row.channel === payload.channel)[0]
            if(found){
                return state
            }
            return {
                ...state,
                joinedList: [...state.joinedList, payload]
            }
        case ACTION_LEAVE_CHANNEL:
            return {
                ...state,
                joinedList: state.joinedList.filter(row => row.channel !== payload)
            }
        case ACTION_UPDATE_CHANNEL:
            return {
                ...state,
                list: state.list.map(row => {
                    if(row.channel === payload.channel){
                        return payload
                    }
                    return row
                }),
                joinedList: state.joinedList.map(row => {
                    if(row.channel === payload.channel){
                        return payload
                    }
                    return row
                })
            }
        case ACTION_STREAM_WITH_CHANNEL:
            return {
                ...state,
                list: state.list.map(row => {
                    if(row.channel === payload.channel){
                        return {
                            ...row,
                            stream_with: payload.userId
                        }
                    }
                    return row
                }),
                joinedList: state.joinedList.map(row => {
                    if(row.channel === payload.channel){
                        return {
                            ...row,
                            stream_with: payload.userId
                        }
                    }
                    return row
                })
            }
        case ACTION_LOAD_ERROR_CHANNEL:
            return {
                ...state,
                loading: false,
                list: [],
                hasMore: false
            }
        case ACTION_GLOBAL_LOADING:
            return {
                ...state,
                globalLoading: true
            }
        case ACTION_GLOBAL_LOADED:
            return {
                ...state,
                globalLoading: false,
                globalChannels: payload.list
            }
        default:
            return state;
    }
}

export function loadChannels({filter, loadMore, resultLimit}) {
    return async (dispatch, getState) => {
        const channelsState = getState().liveChannels
        if(channelsState.loading || (loadMore && !channelsState.hasMore)) {
            return;
        }
        if(resultLimit) {
            limit = resultLimit
        } else {
            limit = 24
        }
        if(!filter) {
            filter = {}
        }
        if(!filter.sex){
            filter.sex = 'all'
        }
        let offset = channelsState.offset
        let excludeChannels = []
        if(loadMore){
            offset += limit
            excludeChannels = channelsState.list.map(row=>row.channel).join(',')
        }
        dispatch({
            type: ACTION_LOADING,
            payload: {
                loadMore
            }
        })
        try {
            const filters = {
                excludeChannels,
                offset, 
                limit
            }
            
            let match
            if((match = filter.sex.match(/sex([0-9]+)/))) {
                filters.sex = match[1]
            }
            if(filter.excludeSex){
                filters.excludeSex = filter.excludeSex
            }
            const { list, total } = await channels(filters)
            dispatch({
                type: ACTION_LOADED,
                payload: {
                    loadMore,
                    offset,
                    total,
                    list
                }
            })
        } catch (e) { 
            dispatch({
                type: ACTION_LOAD_ERROR_CHANNEL,
                payload: null
            })
            HandleAppError(e)
        }
    }
}

export function loadGlobalChannels(){
    return async dispatch => {
        dispatch({
            type: ACTION_GLOBAL_LOADING,
        })
        try {
            const { list } = await channels({global: true, offset: 0, limit: 6})
            dispatch({
                type: ACTION_GLOBAL_LOADED,
                payload: {
                    list
                }
            })
        } catch (e) {}
    }
} 

export function getUserChannel() {
    return async dispatch => {
        try {
            const result = await getChannel()
            const channel = result['channel']
            dispatch({
                type: ACTION_GET_USER_CHANNEL,
                payload: channel
            })
            return channel
        } catch (e) { }
    }
}

export function createChannel(data){
    return async dispatch => {
        try {
            const channel = await create(data)
            dispatch({
                type: ACTION_CREATE_CHANNEL,
                payload: channel
            })
            return channel
        } catch(e) {
            HandleAppError(e)
        }
    }
}

export function joinChannel(channel, code, pin){
    return async (dispatch, getState) => {
        const channelsState = getState().liveChannels
        if(channelsState.joinedList.filter(row => row.channel === channel)[0]){
            return;
        }
        const user = getState().app.user
        try {
            const result = await join(channel, code, pin)
            channel = result.channel
            channel.token = result.token
            channel.messages = result.messages
            channel.promote = result.promote
            channel.usersLiveTimes = result.users_live_times
            channel.subscription = createLiveChannelSubscription(user.realtime.url , user.realtime.token, channel.channel)
            GATracker.event({
                category: 'LiveChannels',
                action: 'Join',
                label: channel.author && channel.author.id === user.id ? 'Author' : 'Non-author'
            })
            dispatch({
                type: ACTION_JOIN_CHANNEL,
                payload: channel
            })
        } catch(e) {
            HandleAppError(e)
        }
    }
}

export function leaveChannel(channelId){
    return dispatch => {
        dispatch({
            type: ACTION_LEAVE_CHANNEL,
            payload: channelId
        })
    }
}

export function updateChannel(channel){
    return dispatch => {
        dispatch({
            type: ACTION_UPDATE_CHANNEL,
            payload: channel
        })
    }
}

export function streamWithUser(channelId, userId){
    return dispatch => {
        try {
            streamWith(channelId, userId)
            dispatch({
                type: ACTION_STREAM_WITH_CHANNEL,
                payload: {
                    channelId,
                    userId
                }
            })
        } catch (e) { }
    }
}