import { createSelector } from 'reselect'

import { createMuiTheme } from '@material-ui/core/styles'

const themes = {
    default: createMuiTheme({
        palette: {
            primary: {
                light: '#774579',
                main: '#561758',
                dark: '#3c103d',
                contrastText: '#fff',
            }
        }
    }),
    dark: createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                light: '#774579',
                main: '#561758',
                dark: '#3c103d',
                contrastText: '#fff',
            }
        }
    })
}

export const getTheme = createSelector(state => state.app.theme, theme => {
    return theme in themes ? themes[theme] : themes.default;
})