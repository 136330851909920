import { ACTION_INIT as APP_INIT } from './app'

const ACTION_ADD = 'NOTIFICATIONS/ADD',
    ACTION_UPDATE = 'NOTIFICATIONS/UPDATE',
    ACTION_REMOVE = 'NOTIFICATIONS/REMOVE',
    ACTION_READ = 'NOTIFICATIONS/READ',
    ACTION_MUTE = 'NOTIFICATIONS/MUTE';

const STORAGE_KEY = 'notifications.mute';

let EVENT_ID = 0;

const initState = {
    events: [],
    unread: 0,
    mute: false
}

function getRestState(state, events) {
    return {
        mute: state.mute && state.mute.expireAt > new Date() ? state.mute : false,
        unread: events.reduce(ev=>ev.view ? 0 : 1, 0)
    }
}

const ACTION_CALLBACKS = {
    [APP_INIT]: (state) => {
        let mute = null;
        try {
            mute = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
            mute.value = Number(mute.value)
            mute.expireAt = new Date(mute.expireAt)
            if(mute.expireAt < new Date()) {
                mute = false;
                window.localStorage.removeItem(STORAGE_KEY)
            }
        } catch(e) {}
        if(null === mute) {
            return state;
        }
        return {
            ...state,
            mute
        }
    },
    [ACTION_MUTE]: (state, payload) => {
        return {
            ...state,
            mute: payload
        }
    },
    [ACTION_ADD]: (state, payload)=>{
        const events = state.events.concat();
        events.unshift(payload)
        return {
            ...state,
            events,
            ...getRestState(state, events)
        }
    },
    [ACTION_UPDATE]: (state, payload) => {
        let item;
        const events = state.events.filter(ev=>{
            if(ev.id !== payload.id) {
                return true;
            }
            item = ev;
            return false;
        });
        events.unshift({
            ...item,
            payload
        });
        return {
            ...state,
            events,
            ...getRestState(state, events)
        }
    },
    [ACTION_REMOVE]: (state, payload) => {
        const events = state.events.filter(ev=>ev.id !== payload);
        return {
            ...state,
            events,
            ...getRestState(state, events)
        }
    },
    [ACTION_READ]: (state) => {
        return {
            ...state,
            events: state.events.map(ev => {
                return {
                    ...ev,
                    view: true
                }
            }),
            unread: 0
        }
    }
}

export default function (state = initState, { type, payload }) {
    if(type in ACTION_CALLBACKS) {
        return ACTION_CALLBACKS[type](state, payload);
    }
    return  state;
}

export function addEvent(item) {
    return (dispatch)=>{
        EVENT_ID++;
        dispatch({
            type: ACTION_ADD,
            payload: {
                ...item,
                id: EVENT_ID,
                total: 0
            }
        })
    }
}

export function updateEvent(eventId, data) {
    return {
        type: ACTION_UPDATE,
        payload: {
            id: eventId,
            view: false,
            expire: data.expire,
            date: new Date(),
            data
        }
    }
}

export function removeEvent(eventId) {
    return {
        type: ACTION_REMOVE,
        payload: eventId
    }
}

export function markAsRead() {
    return {
        type: ACTION_READ
    }
}

export function mute(value) {
    return dispatch=>{
        if(!value) {
            window.localStorage.removeItem(STORAGE_KEY)
        } else {
            value = {
                value,
                expireAt: new Date(new Date().getTime()+ ((3600*Number(value))*1000))
            }
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
        }
        dispatch({
            type: ACTION_MUTE,
            payload: value
        })
    }
}